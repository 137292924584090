import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { graphql } from "gatsby";
import Metadata from "./metadata"

const Event = ({ data }) => {
const item = data.kontentItemContentEvent.elements

return (
    <Layout>
        <Metadata pageData={item} siteData={data.site.siteMetadata} />
        <Head />
        <h1>{item.heading.value}</h1>
        <p className="bold-style-remove-this">Date/Time: {item.date.value} {item.time.value}</p>
        <p className="bold-style-remove-this">Location: {item.location.value}</p>
        <div dangerouslySetInnerHTML={{ __html: item.body_copy.value }} />
    </Layout>
    )
}

export default Event

export const query = graphql`
  query eventQuery($slug: String!) {
    kontentItemContentEvent(fields: { slug: { eq: $slug } }) {
      elements {
        heading {
          value
        }
        body_copy {
          value
        }
        date {
          value(formatString: "DD MMMM YYYY")
        }
        time {
          value
        }
        location {
          value
        }
        slug {
          value
        }
        metadata__title {
          value
        }
        metadata__description {
          value
        }
        metadata__image {
          value {
            url
          }
        }
      }
    }
    site {
        siteMetadata {
            lang
            siteUrl
            websiteTitle
            description
        }
    }
  }
`
